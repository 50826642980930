// OrderItemsList.js
import React from "react";

const OrderItemsList = ({ items }) => {
  return (
    <div>
      <h3>Items in Your Order</h3>
      {items.map((item, index) => (
        <div key={index}>
          <p>
            <strong>Description:</strong> {item.description}
          </p>
          <p>
            <strong>Quantity:</strong> {item.quantity}
          </p>
          <p>
            <strong>Additional Quantity: </strong>
            {parseInt(item.additionalQuantity || 0)}
          </p>
          <p>
            <strong>Price:</strong> ${item.price.toFixed(2)}
          </p>
          <p>
            <strong>Discount:</strong> {item.discount}%
          </p>
          <p>
            <strong>Saved:</strong> ${item.savings.toFixed(2)}
          </p>
          <hr />
        </div>
      ))}
    </div>
  );
};

export default OrderItemsList;
