import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { updateNotes } from "../../store/cartSlice";
import "./orderForm.css";

export const OrderForm = ({ onSubmit, onCancel, initialValues }) => {
  const eventDate = useSelector((state) => state.eventDate); // Access the date from Redux
  const dispatch = useDispatch();
  const notesFromRedux = useSelector((state) => state.cart.notes);

  // Function to format the date from ISO 8601 to YYYY-MM-DD
  const formatDate = (dateString) => {
    return dateString.split("T")[0];
  };

  const [formData, setFormData] = useState({
    name: initialValues.name || "",
    email: initialValues.email || "",
    address: initialValues.address || "",
    city: initialValues.city || "",
    state: initialValues.state || "",
    zip: initialValues.zip || "",
    phone: initialValues.phone || "",
    dateOut: formatDate(eventDate.date),
    dateIn: formatDate(eventDate.date),
    notes: notesFromRedux,
    eventDate: formatDate(eventDate.date),
  });

  useEffect(() => {
    // If the reduxDate changes and you want to update the form field:
    setFormData((prevFormData) => ({
      ...prevFormData,
      dateOut: formatDate(eventDate.date), // Update dateOut with the new reduxDate
      dateIn: formatDate(eventDate.date),
    }));
  }, [eventDate]); // Re-run effect if reduxDate changes

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    // Dispatch updateNotes action when notes change
    if (name === "notes") {
      dispatch(updateNotes(value));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(formData); // Pass the formData back to the parent component
  };

  const US_STATES = [
    { code: "AL", name: "Alabama" },
    { code: "AK", name: "Alaska" },
    { code: "AZ", name: "Arizona" },
    { code: "AR", name: "Arkansas" },
    { code: "CA", name: "California" },
    { code: "CO", name: "Colorado" },
    { code: "CT", name: "Connecticut" },
    { code: "DE", name: "Delaware" },
    { code: "FL", name: "Florida" },
    { code: "GA", name: "Georgia" },
    { code: "HI", name: "Hawaii" },
    { code: "ID", name: "Idaho" },
    { code: "IL", name: "Illinois" },
    { code: "IN", name: "Indiana" },
    { code: "IA", name: "Iowa" },
    { code: "KS", name: "Kansas" },
    { code: "KY", name: "Kentucky" },
    { code: "LA", name: "Louisiana" },
    { code: "ME", name: "Maine" },
    { code: "MD", name: "Maryland" },
    { code: "MA", name: "Massachusetts" },
    { code: "MI", name: "Michigan" },
    { code: "MN", name: "Minnesota" },
    { code: "MS", name: "Mississippi" },
    { code: "MO", name: "Missouri" },
    { code: "MT", name: "Montana" },
    { code: "NE", name: "Nebraska" },
    { code: "NV", name: "Nevada" },
    { code: "NH", name: "New Hampshire" },
    { code: "NJ", name: "New Jersey" },
    { code: "NM", name: "New Mexico" },
    { code: "NY", name: "New York" },
    { code: "NC", name: "North Carolina" },
    { code: "ND", name: "North Dakota" },
    { code: "OH", name: "Ohio" },
    { code: "OK", name: "Oklahoma" },
    { code: "OR", name: "Oregon" },
    { code: "PA", name: "Pennsylvania" },
    { code: "RI", name: "Rhode Island" },
    { code: "SC", name: "South Carolina" },
    { code: "SD", name: "South Dakota" },
    { code: "TN", name: "Tennessee" },
    { code: "TX", name: "Texas" },
    { code: "UT", name: "Utah" },
    { code: "VT", name: "Vermont" },
    { code: "VA", name: "Virginia" },
    { code: "WA", name: "Washington" },
    { code: "WV", name: "West Virginia" },
    { code: "WI", name: "Wisconsin" },
    { code: "WY", name: "Wyoming" },
  ];

  return (
    <div className="order-form-modal">
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="name">Name</label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label htmlFor="address">Delivery Address</label>
          <input
            type="text"
            id="address"
            name="address"
            value={formData.address}
            onChange={handleChange}
            required
          />
        </div>
        <div className="multi-input-container">
          <div>
            <label htmlFor="city">City</label>
            <input
              type="text"
              id="city"
              name="city"
              value={formData.city}
              onChange={handleChange}
              required
            />
          </div>
          <div>
            <label htmlFor="state">State</label>
            <select
              id="state"
              name="state"
              value={formData.state}
              onChange={handleChange}
              required
            >
              <option value="">Select a State</option>
              {US_STATES.map((state) => (
                <option key={state.code} value={state.code}>
                  {state.name}
                </option>
              ))}
            </select>
          </div>
          <div>
            <label htmlFor="zip">Zip Code</label>
            <input
              type="text"
              id="zip"
              name="zip"
              value={formData.zip}
              onChange={handleChange}
              required
            />
          </div>
        </div>
        <div className="date-input-container">
          <div>
            <label htmlFor="dateOut">Delivery Date</label>
            <input
              type="date"
              id="dateOut"
              name="dateOut"
              value={formData.dateOut}
              onChange={handleChange}
              required
            />
          </div>
          <div>
            <label htmlFor="dateIn">Pick Up Date</label>
            <input
              type="date"
              id="dateIn"
              name="dateIn"
              value={formData.dateIn}
              onChange={handleChange}
              required
            />
          </div>
        </div>
        <div>
          <label htmlFor="email">Email</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label htmlFor="phone">Phone</label>
          <input
            type="tel"
            id="phone"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <p style={{ fontSize: "0.9rem" }}>
            * IF DELIVERY AND PICK UP ADDRESS IS DIFFERENT INDICATE THAT IN
            NOTES SECTION
          </p>
        </div>
        <div>
          <label htmlFor="notes">Notes</label>
          <textarea
            id="notes"
            name="notes"
            value={formData.notes}
            onChange={handleChange}
          />
        </div>
        <div className="order-form-actions">
          <button type="submit" className="submit-order-btn">
            SUBMIT ORDER
          </button>
          <button type="button" onClick={onCancel} className="cancel-order-btn">
            CANCEL
          </button>
        </div>
      </form>
    </div>
  );
};
