import React, { useState, useEffect } from "react";
import { getAuth } from "firebase/auth";
import { useSelector } from "react-redux";
import ItemBox from "../itemBox/itemBox";
import { useNavigate } from "react-router-dom";
import "./userFavorites.css";

const UserFavorites = () => {
  const [favorites, setFavorites] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const eventDate = useSelector((state) => state.eventDate);
  const cart = useSelector((state) => state.cart.items);
  const navigate = useNavigate();
  const [itemsWithAvailability, setItemsWithAvailability] = useState([]);

  const fetchFavorites = async () => {
    setLoading(true);
    try {
      const auth = getAuth();
      const token = await auth.currentUser.getIdToken();
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/user/favorites`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();
      setFavorites(data.favorites || []);
      setLoading(false);
    } catch (err) {
      console.error("Failed to fetch favorites:", err);
      setError("Failed to load favorites.");
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchFavorites();
  }, []);

  // Fetch items with availability and discounts
  const fetchItemsAvailability = async () => {
    if (!eventDate.date) {
      alert("Please select a date first.");
      navigate("/date");
      return;
    }

    setLoading(true); // Start loading

    try {
      const auth = getAuth();
      const token = await auth.currentUser.getIdToken();

      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/items/availability`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            itemIds: favorites, // Send favorite item IDs
            eventDate: eventDate.date.split("T")[0], // Send event date in YYYY-MM-DD format
          }),
        }
      );

      const data = await response.json();
      setItemsWithAvailability(data.items); // Assuming the backend responds with a list of items
    } catch (err) {
      console.error("Error fetching availability:", err);
      setError("Failed to load item availability.");
    } finally {
      setLoading(false); // Stop loading regardless of success or failure
    }
  };

  // Automatically fetch items availability when event date or favorites change
  useEffect(() => {
    if (eventDate.date && favorites.length > 0) {
      fetchItemsAvailability();
    }
    // eslint-disable-next-line
  }, [eventDate.date, favorites]);

  const toggleFavorite = async (itemId) => {
    try {
      const auth = getAuth();
      const token = await auth.currentUser.getIdToken();
      const isFavorited = favorites.includes(itemId.toString());
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/user/favorites`,
        {
          method: isFavorited ? "DELETE" : "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ itemId }),
        }
      );

      if (response.ok) {
        // Fetch the updated favorites after successfully adding/removing
        await fetchFavorites();
      }
    } catch (error) {
      console.error("Error updating favorites:", error);
    }
  };

  if (loading) return <div>Loading favorites...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div>
      <h2>YOUR FAVORITES</h2>
      {eventDate.date && (
        <p>
          Selected Event Date: {new Date(eventDate.date).toLocaleDateString()}
        </p>
      )}
      {favorites.length === 0 && <p>No favorite items found.</p>}

      {/* Display items with availability */}
      <div className="favorite-items-container">
        {itemsWithAvailability.length > 0 ? (
          itemsWithAvailability.map((item) => (
            <ItemBox
              key={item.ItemID}
              item={item}
              name={item.ItemDescription}
              quantityInCart={
                cart.find((cartItem) => cartItem.id === item.ItemID)
                  ?.quantity || 0
              }
              isFavorited={favorites.includes(item.ItemID.toString())}
              toggleFavorite={toggleFavorite}
            />
          ))
        ) : (
          <p>No items to display. Select a date to check availability.</p>
        )}
      </div>
    </div>
  );
};

export default UserFavorites;
