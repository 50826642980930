import React from "react";
import { useNavigate, NavLink } from "react-router-dom";
import { useAuth } from "../context/authContext";
import { IconShoppingCart } from "@tabler/icons-react";
import { IconUserCircle } from "@tabler/icons-react";
import { useSelector } from "react-redux";

export default function Header() {
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const cartItems = useSelector((state) => state.cart.items);

  const handleUserClick = () => {
    // Navigate to /user-dashboard if authenticated, otherwise navigate to /auth
    if (currentUser) {
      navigate("/user-dashboard");
    } else {
      navigate("/auth");
    }
  };

  // Calculate the total number of items in the cart
  const totalItemsInCart = cartItems.reduce(
    (total, item) => total + item.quantity,
    0
  );

  return (
    <div
      style={{
        backgroundColor: "#7A9A9A",
        width: "100%",
        position: "fixed",
        top: 0,
        zIndex: 1000, // Ensure header stays on top of other content
        boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          maxWidth: "1200px",
          margin: "0 auto",
          padding: "10px 20px",
        }}
      >
        {/* Left-aligned item for cart icon */}
        <div style={{ flex: 1 }}>
          <NavLink to="/cart" style={{ position: "relative" }}>
            <IconShoppingCart size={48} color="black" />
            {totalItemsInCart > 0 && (
              <span
                style={{
                  position: "absolute",
                  top: -30,
                  right: 0,
                  backgroundColor: "red",
                  borderRadius: "50%",
                  width: "30px",
                  height: "20px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "white",
                  fontSize: "14px",
                }}
              >
                {totalItemsInCart}
              </span>
            )}
          </NavLink>
        </div>

        {/* Center-aligned item for logo */}
        <div style={{ flex: 2, textAlign: "center" }}>
          <NavLink to="/date">
            <img
              src={process.env.PUBLIC_URL + "/Priorum Logo.svg"}
              alt="Priorum"
              style={{ width: "auto", height: "80px" }}
            />
          </NavLink>
        </div>

        {/* Right-aligned item for user account */}
        <div style={{ flex: 1, textAlign: "right" }}>
          <button
            onClick={handleUserClick}
            style={{ background: "none", border: "none", cursor: "pointer" }}
          >
            <IconUserCircle size={48} color="black" />
            <div
              style={{
                maxWidth: "200px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {currentUser?.displayName}
            </div>
          </button>
        </div>
      </div>
    </div>
  );
}
