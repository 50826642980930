// src/routes/ProtectedRoute.js
// import React, { useEffect, useState } from "react";
// import { useNavigate } from "react-router-dom";
// import { useAuth } from "../context/authContext";
// import axios from "axios";
// import toast from "react-hot-toast";

// export const ProtectedRoute = ({ children, adminOnly = false }) => {
//   const { currentUser } = useAuth();
//   const [authorized, setAuthorized] = useState(false);
//   const navigate = useNavigate();
//   const [loading, setLoading] = useState(true);

//   useEffect(() => {
//     const verifyAccess = async () => {
//       if (!currentUser) {
//         setLoading(false);
//         return;
//       }
//       try {
//         const idToken = await currentUser.getIdToken();
//         const response = await axios.get(
//           `${process.env.REACT_APP_BACKEND_URL}/api/user/profile`,
//           {
//             headers: { Authorization: `Bearer ${idToken}` },
//           }
//         );
//         if (
//           adminOnly &&
//           response.data.role !== "admin" &&
//           response.data.role !== "super-admin"
//         ) {
//           setAuthorized(false);
//           toast.error("Access restricted to administrators.");
//           setLoading(false);
//         } else if (response.data.status !== "active") {
//           toast.error("Your account is pending approval.");
//           setLoading(false);
//         } else {
//           setAuthorized(true);
//         }
//       } catch (error) {
//         toast.error("Failed to verify user status.");
//       }
//       setLoading(false);
//     };

//     verifyAccess();
//     // eslint-disable-next-line
//   }, [currentUser, adminOnly]);

//   if (loading) return <p>Loading...</p>;
//   if (!currentUser || !authorized) return navigate("/");

//   return children;
// };

// src/components/ProtectedRoute.js
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../context/authContext";
import axios from "axios";
import toast from "react-hot-toast";

export const ProtectedRoute = ({ children, adminOnly = false }) => {
  const { currentUser } = useAuth();
  const [authorized, setAuthorized] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const verifyAccess = async () => {
      if (!currentUser) {
        setAuthorized(false);
        setLoading(false);
        return;
      }

      if (!adminOnly) {
        setAuthorized(true);
        setLoading(false);
        return;
      }

      try {
        const idToken = await currentUser.getIdToken();
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/api/user/profile`,
          {
            headers: { Authorization: `Bearer ${idToken}` },
          }
        );

        const { role, status } = response.data;

        if (
          adminOnly &&
          (role === "admin" || role === "super-admin") &&
          status === "active"
        ) {
          setAuthorized(true);
        } else if (status !== "active") {
          toast.error("Your account is pending approval.");
          setAuthorized(false);
        } else {
          toast.error("Access restricted to administrators.");
          setAuthorized(false);
        }
      } catch (error) {
        toast.error("Failed to verify user status.");
        console.error("ProtectedRoute verification error:", error);
        setAuthorized(false);
      }
      setLoading(false);
    };

    verifyAccess();
    // eslint-disable-next-line
  }, [currentUser, adminOnly]);

  // if (loading) return <p>Loading...</p>;
  // if (!currentUser || !authorized) {
  //   navigate("/");
  //   return null;
  // }

  // return children;
  useEffect(() => {
    if (!loading && (!currentUser || !authorized)) {
      navigate("/");
    }
  }, [loading, authorized, currentUser, navigate]);

  if (loading) return <p>Loading...</p>;

  return authorized ? children : null;
};
