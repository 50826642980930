import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { useLocation } from "react-router-dom";
import "./itemBox.css";
import { IconHeart } from "@tabler/icons-react";
import { IconHeartFilled } from "@tabler/icons-react";

// export default ItemBox;
const ItemBox = ({
  item,
  quantityInCart,
  name,
  isFavorited,
  toggleFavorite,
}) => {
  const location = useLocation();
  const [isPulsing, setIsPulsing] = useState(false);

  // Function to handle heart click and stop event propagation
  const handleHeartClick = (e) => {
    e.preventDefault(); // Prevents the default behavior of the link
    e.stopPropagation(); // Stops the event from propagating to the parent NavLink
    // Trigger pulse animation
    setIsPulsing(true);
    toggleFavorite(item.ItemID); // Calls the toggleFavorite function
    // Remove the pulsing class after animation duration
    setTimeout(() => {
      setIsPulsing(false);
    }, 600); // Match the pulse animation duration in milliseconds
  };

  const isAvailable = item.AvailableQuantity > 0;

  return (
    <NavLink
      to={
        isAvailable
          ? `/item/${item.ItemID}?from=${encodeURIComponent(
              location.pathname
            )}?name=${encodeURIComponent(name)}`
          : null
      }
      className={`item-box ${isAvailable ? "" : "not-clickable"}`}
      onClick={(e) => !isAvailable && e.preventDefault()} // Prevent navigation if not available
    >
      <div className="item-page-image-container">
        <img
          src={`${process.env.REACT_APP_BLOB_IMAGE_URL}/${item.ItemID}.jpg`}
          // alt={"Product Image Coming Soon"}
          alt={item.ItemDescription}
          className="item-page-image"
        />
      </div>
      <div style={{ fontWeight: "bold" }}>{item.ItemDescription}</div>
      <div>Rental Price: ${item.RentalPrice}</div>
      {item.DiscountsApplied && item.DiscountsApplied.length > 0 && (
        <>
          <div>
            Available for Discount:{" "}
            {item.DiscountsApplied[0].quantity - quantityInCart}
          </div>
          <div>
            Discount:{" "}
            <span style={{ fontWeight: "bold", color: "red" }}>
              {item.DiscountsApplied[0].discountPercentage}%
            </span>{" "}
            off
          </div>
        </>
      )}
      {!item.DiscountsApplied ||
        (item.DiscountsApplied.length === 0 && (
          <div>
            {isAvailable
              ? `Available Quantity: ${item.AvailableQuantity - quantityInCart}`
              : "Not Available on This Date"}
          </div>
        ))}
      {quantityInCart > 0 && <div>Items Added to Cart: {quantityInCart}</div>}

      {/* Make the heart clickable without navigating */}
      <div
        className={`favorite-icon ${isPulsing ? "pulse-animation" : ""}`}
        onClick={handleHeartClick}
        title={isFavorited ? "Unfavorite" : "Favorite"}
      >
        {isFavorited ? (
          <IconHeartFilled color="red" />
        ) : (
          <IconHeart color="black" />
        )}
      </div>
    </NavLink>
  );
};

export default ItemBox;
