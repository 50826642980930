// OrderList.jsx
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  removeFromCart,
  clearCart,
  updateAdditionalQuantities,
} from "../../store/cartSlice";
import { clearDate } from "../../store/dateSlice";
import { useNavigate } from "react-router-dom";
import { OrderForm } from "../orderForm/orderForm";
import { useAuth } from "../../context/authContext";
import "./orderList.css";
import toast from "react-hot-toast";
import { IconTrash } from "@tabler/icons-react";
import QuantityExceededModal from "../quantityExceededModal/quantityExceededModal";
import ConfirmOrderModal from "../confirmOrderModal/confirmOrderModal";

export default function OrderList() {
  const cart = useSelector((state) => state.cart.items);
  const eventDate = useSelector((state) => state.eventDate); // Access the date from Redux
  const additionalQuantities = useSelector(
    (state) => state.cart.additionalQuantities
  );
  const mandatoryQuantities = useSelector(
    (state) => state.cart.mandatoryQuantities
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { currentUser, userDetails } = useAuth();
  const backendUrl = process.env.REACT_APP_BACKEND_URL;

  // State to manage modal visibility
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [modalData, setModalData] = useState({ isOpen: false, catLink: "" });

  const [isModalOpen, setIsModalOpen] = useState(false); // Confirm Order Modal State

  const [formData, setFormData] = useState(null);

  // Function to toggle form visibility
  const toggleFormVisibility = () => setIsFormVisible(!isFormVisible);

  const handleRemove = (id) => {
    const isConfirmed = window.confirm(
      "Are you sure you want to remove this item?"
    );
    if (isConfirmed) {
      dispatch(removeFromCart(id));
    }
  };

  // Calculate total prices and savings for each item
  const calculateItemFinals = (item) => {
    const pricePerItem = item.itemDetails.RentalPrice;
    const quantity = item.quantity;
    const discountPercentage =
      item.itemDetails.DiscountsApplied?.[0]?.discountPercentage || 0;
    const discountAmount = pricePerItem * quantity * (discountPercentage / 100);
    const orgPrice = pricePerItem * quantity;
    const finalPrice = pricePerItem * quantity - discountAmount;

    return {
      orgPrice: orgPrice,
      finalPrice: finalPrice,
      savings: discountAmount,
    };
  };

  // Calculate totals for all items in the cart
  const totals = cart.reduce(
    (acc, item) => {
      const { finalPrice, savings } = calculateItemFinals(item);
      acc.totalRentalPrice += finalPrice;
      acc.totalSavings += savings;
      return acc;
    },
    { totalRentalPrice: 0, totalSavings: 0 }
  );

  const handleAdditionalQuantityChange = (itemId, quantity) => {
    const numericValue = parseInt(quantity, 10); // Ensure the value is an integer
    const item = cart.find((item) => item.id === itemId);
    const totalQuantityOwned = item.itemDetails.QuantityOwned;

    // Check if the total quantity exceeds available quantity
    if (numericValue + item.quantity > totalQuantityOwned) {
      setModalData({ isOpen: true, catLink: item.itemDetails.catLink });
      return; // Don't update if the quantity exceeds availability
    }
    if (numericValue >= 0) {
      dispatch(updateAdditionalQuantities({ itemId, quantity: numericValue }));
    }
  };

  const validateQuantities = (itemId) => {
    const item = cart.find((item) => item.id === itemId);
    const additionalQuantity = additionalQuantities[itemId] || 0;
    const totalQuantity = item.quantity + additionalQuantity;
    const requiredIncrement = mandatoryQuantities[itemId];
    const totalQuantityOwned = item.itemDetails.QuantityOwned;

    if (requiredIncrement && totalQuantity % requiredIncrement !== 0) {
      toast.error(
        `Total quantity must be in increments of ${requiredIncrement}. Please adjust.`
      );

      // Optionally reset to last valid or calculate nearest valid increment
      let nearestValid =
        totalQuantity - (totalQuantity % requiredIncrement) + requiredIncrement;
      if (nearestValid > totalQuantityOwned) {
        setModalData({ isOpen: true, catLink: item.itemDetails.catLink });
        nearestValid = totalQuantity - (totalQuantity % requiredIncrement);
      }
      dispatch(
        updateAdditionalQuantities({
          itemId,
          quantity: nearestValid - item.quantity,
        })
      );
    }
  };

  useEffect(() => {
    cart.forEach((item) => {
      const requiredIncrement = mandatoryQuantities[item.id] || 0;
      if (requiredIncrement > 0) {
        const currentQuantity = item.quantity;
        const currentAdditionalQuantity = additionalQuantities[item.id] || 0;
        const totalCurrentQuantity =
          currentQuantity + currentAdditionalQuantity;

        if (totalCurrentQuantity % requiredIncrement !== 0) {
          const additionalNeeded =
            (requiredIncrement - (totalCurrentQuantity % requiredIncrement)) %
            requiredIncrement;
          if (additionalNeeded !== currentAdditionalQuantity) {
            dispatch(
              updateAdditionalQuantities({
                itemId: item.id,
                quantity: additionalNeeded,
              })
            );
          }
        }
      }
    });
    // eslint-disable-next-line
  }, [cart, mandatoryQuantities, dispatch]);

  if (cart.length === 0) {
    return (
      <div>
        <p>Your cart is empty.</p>
        <button onClick={() => navigate("/date")}>BACK TO HOME</button>
      </div>
    );
  }

  const handleFormSubmit = async (submittedFormData) => {
    setFormData(submittedFormData); // Save form data for later use in confirmation
    setIsModalOpen(true); // Open the confirmation modal
  };

  const handleConfirmOrder = async () => {
    setIsModalOpen(false); // Close modal after confirmation

    if (!formData) {
      console.error("Form data is missing");
      return;
    }

    // Concatenate the full address from the formData
    const fullAddress = `${formData.address}, ${formData.city}, ${formData.state} ${formData.zip}`;

    // Close the form modal
    toggleFormVisibility();

    // Prepare the order details
    const orderDetails = {
      ...formData,
      address: fullAddress,
      userId: userDetails._id,
      items: cart.map((item) => ({
        itemID: item.id,
        description: item.itemDetails.ItemDescription,
        quantity: item.quantity,
        additionalQuantity: additionalQuantities[item.id] || 0,
        price: calculateItemFinals(item).finalPrice,
        discount:
          item.itemDetails.DiscountsApplied?.[0]?.discountPercentage || 0,
        savings: calculateItemFinals(item).savings || 0,
      })),
      totalRentalPrice: totals.totalRentalPrice,
      totalSavings: totals.totalSavings,
      status: "pending",
    };

    try {
      // Send order details to the backend
      const response = await fetch(`${backendUrl}/api/orders`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(orderDetails),
      });

      if (!response.ok) {
        throw new Error("Failed to submit order");
      }

      // Display toast success message
      toast.success("Order submitted successfully.");
      // Navigate to /order-summary
      dispatch(clearCart());
      dispatch(clearDate());
      navigate("/order-summary", { state: { orderDetails } });
    } catch (error) {
      console.error("Error submitting order:", error);
      // Display toast error message
      toast.error("Failed to submit order. Please try again later.");
      // Navigate to /dates
      navigate("/date");
    }
  };

  return (
    <div className="order-list-container">
      <QuantityExceededModal
        isOpen={modalData.isOpen}
        onClose={() => setModalData({ ...modalData, isOpen: false })}
        catLink={modalData.catLink}
      />
      <div className="cart-items-container">
        <h2 className="cart-header">YOUR PRIORUM CART</h2>
        {cart.map((item) => (
          <div key={item.id} className="cart-item">
            <img
              src={`${process.env.REACT_APP_BLOB_IMAGE_URL}/${item.id}.jpg`}
              alt={item.itemDetails.ItemDescription}
              className="cart-item-image"
            />
            <div className="cart-item-details">
              <div className="item-description">
                <h3>{item.itemDetails.ItemDescription}</h3>
                <div className="price-quantity">
                  <span className="strikethrough">
                    ${calculateItemFinals(item).orgPrice.toFixed(2)}
                  </span>
                  <span className="final-price">
                    ${calculateItemFinals(item).finalPrice.toFixed(2)}
                  </span>
                </div>
                <div className="quantity-remove">
                  <p>Quantity: {item.quantity}</p>
                  <button
                    onClick={() => handleRemove(item.id)}
                    style={{
                      background: "none",
                      border: "none",
                      cursor: "pointer",
                      color: "black",
                    }}
                  >
                    <IconTrash />
                  </button>
                </div>
              </div>
              {mandatoryQuantities[item.id] > 0 && (
                <p>
                  This item must be submitted in quantities of{" "}
                  <strong>{mandatoryQuantities[item.id]}</strong>.
                </p>
              )}
              <p>
                If <strong>higher quantities</strong> at{" "}
                <strong>regular pricing</strong> are needed{" "}
                <strong>enter below</strong>. Otherwise please leave empty.
              </p>
              <label
                htmlFor={`additionalQuantity-${item.id}`}
                style={{ display: "block", marginBottom: "5px" }}
              >
                Additional Quantity (optional):
              </label>
              <input
                id={`additionalQuantity-${item.id}`}
                style={{ marginBottom: "20px" }}
                type="number"
                value={additionalQuantities[item.id] || ""}
                onChange={(e) =>
                  handleAdditionalQuantityChange(item.id, e.target.value)
                }
                onBlur={() => validateQuantities(item.id)}
              />
            </div>
          </div>
        ))}
      </div>
      <div className="checkout-container">
        <div className="total-box">
          <h3>Total</h3>
          <p>
            Total Rental Price for Discounted Priorum Items Only: $
            {totals.totalRentalPrice.toFixed(2)}
          </p>
          <p>Total Savings: ${totals.totalSavings.toFixed(2)}</p>
        </div>

        <p>
          * Price does not include delivery, labor, and additional quantities
        </p>
        <p>* ORDER IS NOT FINAL UNTIL PAYMENT IS MADE</p>

        {/* Button to open order form */}
        <button onClick={toggleFormVisibility}>PROCEED TO CHECKOUT</button>

        {isFormVisible && (
          <OrderForm
            onSubmit={(formData) => handleFormSubmit(formData)}
            onCancel={toggleFormVisibility}
            initialValues={{
              name: currentUser.displayName,
              email: currentUser.email,
              phone: userDetails.phone,
              address: userDetails.address,
              zip: userDetails.zip,
              city: userDetails.city,
              state: userDetails.state,
            }}
          />
        )}
      </div>
      <ConfirmOrderModal
        isOpen={isModalOpen}
        message={
          <span>
            Are you sure you want to submit your order? Please confirm your
            event date of
            <strong>
              {" "}
              {new Date(eventDate.date).toLocaleDateString()}{" "}
            </strong>{" "}
            is correct.
          </span>
        }
        onConfirm={handleConfirmOrder} // Call the actual form submit after confirmation
        onCancel={() => setIsModalOpen(false)} // Close the modal if they cancel
      />
    </div>
  );
}
