// Categories.js
import React, { useState, useEffect } from "react";
import { useParams, NavLink, useNavigate, useLocation } from "react-router-dom";
import "./categories.css";

const Categories = () => {
  const [categories, setCategories] = useState([]);
  const { supercategoryId } = useParams();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const name = queryParams.get("name");

  useEffect(() => {
    const backendUrl = process.env.REACT_APP_BACKEND_URL;
    const url = `${backendUrl}/api/categories/supercategories/${supercategoryId}`;

    setLoading(true); // Start loading
    fetch(url)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setCategories(data);
        setError(null); // Clear any previous errors
      })
      .catch((error) => {
        console.error("Failed to fetch categories:", error);
        setError("Failed to fetch categories. PLEASE RELOAD");
      })
      .finally(() => setLoading(false)); // Stop loading regardless of outcome
  }, [supercategoryId]);

  if (loading) return <p>Loading...</p>;
  if (error)
    return (
      <div>
        <p>Error: {error} </p>
        <p>
          {" "}
          DON'T RELOAD, return to calendar and click OK again. Reloading clears
          cart
        </p>
        <button onClick={() => navigate("/date")}>BACK TO CALENDAR</button>
        <p>If that does not work please reload</p>
      </div>
    );

  return (
    <div className="categories-container">
      <h1> {decodeURIComponent(name)} </h1>
      <div className="categories-grid">
        {categories.map((category) => (
          <NavLink
            key={category._id}
            to={`/home/category/${category._id}?name=${encodeURIComponent(
              category.name
            )}`}
            className="category-box"
          >
            {category.name}
          </NavLink>
        ))}
      </div>
    </div>
  );
};

export default Categories;
