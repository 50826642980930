import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { addToCart } from "../../store/cartSlice";
import { quantityRules } from "../../config/quantityRules";
import "./itemPage.css";
import toast from "react-hot-toast";

const ItemPage = () => {
  const { id: itemId } = useParams(); // Get itemID from route params
  const [itemDetails, setItemDetails] = useState({});
  const [quantityToRent, setQuantityToRent] = useState("");
  const [loading, setLoading] = useState(false); // Loading state
  const eventDate = useSelector((state) => state.eventDate); // Access the date from Redux
  const cart = useSelector((state) => state.cart.items); // Access the cart from Redux
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const backLink = queryParams.get("from") || "/home";
  // Before using itemCart, check if the item is found in the cart and use .quantity if it exists, otherwise default to 0
  const itemInCart = cart.find(
    (cartItem) => cartItem.id === itemDetails.ItemID
  );
  const itemCartQuantity = itemInCart ? itemInCart.quantity : 0;

  //useEffect to fetch the specific item
  useEffect(() => {
    const fetchItemDetails = async () => {
      setLoading(true); // Start loading
      const backendUrl = process.env.REACT_APP_BACKEND_URL;
      let url = `${backendUrl}/api/items/${itemId}`;
      // Append date range to URL if available
      if (eventDate) {
        const eventDateString = eventDate.date.split("T")[0]; // Format eventDate as necessary
        url += `?eventDate=${eventDateString}`;
      }
      try {
        const response = await fetch(url);
        const data = await response.json();
        setItemDetails(data);
      } catch (error) {
        console.error("Failed to fetch item details:", error);
      } finally {
        setLoading(false); // Stop loading
      }
    };

    // Check if the date range is not set
    if (!eventDate.date) {
      alert("Please select a date first.");
      navigate("/date");
    } else {
      fetchItemDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemId]);

  // Calculate the total savings from the discount
  const totalSavings = itemDetails.DiscountsApplied?.[0]?.discountPercentage
    ? (itemDetails.RentalPrice *
        quantityToRent *
        itemDetails.DiscountsApplied[0].discountPercentage) /
      100
    : 0;

  const finalPrice = itemDetails.RentalPrice
    ? itemDetails.RentalPrice * quantityToRent - totalSavings
    : 0;

  // Ensure that quantityToRent is a valid number before using it in calculations
  const handleQuantityChange = (e) => {
    const newValue = e.target.value;
    // Allow only numbers or empty input
    if (newValue === "" || /^\d+$/.test(newValue)) {
      setQuantityToRent(newValue);
    }
  };

  // Dispatch set up for redux
  const dispatch = useDispatch();

  const getEntryQuantity = () => {
    return (
      quantityRules.items[itemId] ||
      quantityRules.supercategories[itemDetails.superCategory] ||
      null
    ); // Return null if no specific rule exists
  };

  const mandatoryQuantity = getEntryQuantity();

  // Adds an item to cart
  const handleAddToCart = () => {
    const itemInCart = cart.find((item) => item.id === itemDetails.ItemID); // Find if the item is already in the cart
    const currentQuantityInCart = itemInCart ? itemInCart.quantity : 0; // Current quantity in the cart
    const requestedQuantity = parseInt(quantityToRent, 10); // Requested quantity to add

    if (isNaN(requestedQuantity) || requestedQuantity <= 0) {
      alert("Please enter a valid quantity to rent.");
      return;
    }

    // Calculate the new total quantity if the item were added
    const newTotalQuantity = currentQuantityInCart + requestedQuantity;

    // Check against AvailableQuantity
    if (newTotalQuantity > itemDetails.AvailableQuantity) {
      alert(
        `Total quantity exceeds available stock. Available quantity is ${itemDetails.AvailableQuantity}.`
      );
      return;
    } else if (
      itemDetails.DiscountsApplied &&
      itemDetails.DiscountsApplied.length > 0 &&
      newTotalQuantity > itemDetails.DiscountsApplied[0].quantity
    ) {
      alert(
        `Please enter a quantity less than or equal to the available discount quantity (${itemDetails.DiscountsApplied[0].quantity})`
      );
      return;
    }
    // Dispatch the action to add the item to the cart
    dispatch(
      addToCart({
        id: itemDetails.ItemID,
        quantity: requestedQuantity, // Ensure quantity is an integer
        itemDetails: {
          ...itemDetails, // Spread the item details
          supercategoryId: itemDetails.superCategory,
          catLink: backLink,
          totalSavings: totalSavings, // Add total savings
          finalPrice: finalPrice, // Add final price after discount
        },
        mandatoryQuantities: mandatoryQuantity,
      })
    );
    setQuantityToRent(""); // Reset input field
    toast.success("Item added to cart.");
  };

  const imageUrl = `${process.env.REACT_APP_BLOB_IMAGE_URL}/${itemId}.jpg`;

  return (
    <div className="item-page">
      <div className="item-details">
        {loading ? (
          <p>Loading item details...</p> // Loading message
        ) : (
          <>
            {eventDate.date && (
              <p>
                Selected Event Date:{" "}
                {new Date(eventDate.date).toLocaleDateString()}
              </p>
            )}
            <h2>{itemDetails.ItemDescription}</h2>
            {<div>Items Added to Cart: {itemCartQuantity}</div>}
            <p>Rental Price: ${itemDetails.RentalPrice}</p>
            {itemDetails.DiscountsApplied?.length > 0 ? (
              <>
                <p>
                  Available for Discount:{" "}
                  {itemDetails.DiscountsApplied[0].quantity - itemCartQuantity}
                </p>
                <p>
                  Discount: {itemDetails.DiscountsApplied[0].discountPercentage}
                  % off
                </p>
              </>
            ) : (
              <p>
                Available for Rent:{" "}
                {itemDetails.AvailableQuantity - itemCartQuantity}
              </p>
            )}
            <p className="total-savings-box">
              Total Savings: ${totalSavings.toFixed(2)}
            </p>
            <p>Final Price: ${finalPrice.toFixed(2)}</p>
            {getEntryQuantity() && (
              <div
                style={{
                  border: "1px solid #ccc",
                  padding: "10px",
                  margin: "20px", // Optionally adding margin to create space around the box
                }}
              >
                {" "}
                <p>(Please enter in quantities of {getEntryQuantity()})</p>
                <p>
                  *If discount quantity is less than mandatory quantity enter
                  the rest of the quantity
                </p>
                <p>
                  as additional quantity at regular pricing in the cart page
                </p>
              </div>
            )}
            <div>
              <label htmlFor="quantityToRent">Quantity to Rent:</label>
              <input
                type="number"
                id="quantityToRent"
                value={quantityToRent}
                min="0"
                onChange={handleQuantityChange}
              />
            </div>
            <br></br>
            <button onClick={handleAddToCart}>ADD TO ORDER</button> <br></br>
            <br></br> {/*Change the br's later when styling */}
            <button onClick={() => navigate(backLink)}>BACK TO CATEGORY</button>
            <p>
              * If higher quantities at regular pricing are needed enter on the
              cart page.
            </p>
          </>
        )}
      </div>
      <img
        src={imageUrl}
        alt={itemDetails.ItemDescription}
        className="item-image"
      />
    </div>
  );
};

export default ItemPage;
