import React, { createContext, useContext, useEffect, useState } from "react";
import { auth } from "../config/firebaseConfig";
import { updateProfile } from "firebase/auth";
import { sendPasswordResetEmail } from "firebase/auth";
import { clearCart } from "../store/cartSlice";
import { clearDate } from "../store/dateSlice";
import { useDispatch } from "react-redux";
import axios from "axios";
import toast from "react-hot-toast";

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const dispatch = useDispatch();
  const [currentUser, setCurrentUser] = useState(null);
  const [userDetails, setUserDetails] = useState({});
  let refreshInterval = null;

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (!user) {
        // User is signed out
        setCurrentUser(null);
        setUserDetails({});
        clearIntervals();
      } else {
        setCurrentUser(user);
        const idToken = await user.getIdToken();
        axios
          .get(`${process.env.REACT_APP_BACKEND_URL}/api/user/profile`, {
            headers: { Authorization: `Bearer ${idToken}` },
          })
          .then(async (response) => {
            // Check if we need to update the profile
            if (response.data.name && response.data.name !== user.displayName) {
              await updateProfile(user, { displayName: response.data.name });
              // Re-fetch the user to get the updated profile
              user.reload().then(() => {
                setCurrentUser(user);
              });
            }
            setUserDetails(response.data); // MongoDB user details
            startRefreshTokenInterval(user);
          })
          .catch((error) => {
            toast.error("Failed to fetch user details");
            console.error("Fetch user details error:", error);
          });
      }
    });

    return () => {
      unsubscribe();
      clearIntervals();
    };
    // eslint-disable-next-line
  }, []);

  const startRefreshTokenInterval = (user) => {
    refreshInterval = setInterval(() => {
      user
        .getIdToken(true)
        .catch((error) => console.error("Token refresh error:", error));
    }, 600000); // Refresh every 10 minutes
  };

  const clearIntervals = () => {
    if (refreshInterval) {
      clearInterval(refreshInterval);
    }
  };

  const login = (email, password) =>
    auth.signInWithEmailAndPassword(email, password);
  const signup = (email, password) =>
    auth.createUserWithEmailAndPassword(email, password);
  const logout = () => {
    dispatch(clearCart());
    dispatch(clearDate());
    auth.signOut();
    setUserDetails({});
    clearIntervals();
  };

  const resetPassword = (email) => {
    return sendPasswordResetEmail(auth, email)
      .then(() => {
        toast.success("Password reset email sent.");
      })
      .catch((error) => {
        toast.error(`Error sending password reset email: ${error.message}`);
      });
  };
  const updateUserDetails = (updatedDetails) => {
    setUserDetails((prevDetails) => ({
      ...prevDetails,
      ...updatedDetails,
    }));
  };

  const value = {
    currentUser,
    userDetails, // Provide MongoDB user details alongside Firebase auth
    updateUserDetails,
    login,
    signup,
    logout,
    resetPassword,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
