import { createSlice } from "@reduxjs/toolkit";

export const cartSlice = createSlice({
  name: "cart",
  initialState: {
    items: [],
    additionalQuantities: {},
    mandatoryQuantities: {},
    notes: "",
  },
  reducers: {
    addToCart: (state, action) => {
      const existingIndex = state.items.findIndex(
        (item) => item.id === action.payload.id
      );

      if (existingIndex >= 0) {
        state.items[existingIndex].quantity += action.payload.quantity;
      } else {
        state.items.push(action.payload);
        // Initialize additional quantity when adding a new item
        state.additionalQuantities[action.payload.id] = 0;
        state.mandatoryQuantities[action.payload.id] =
          action.payload.mandatoryQuantities || 0;
      }
    },
    removeFromCart: (state, action) => {
      state.items = state.items.filter((item) => item.id !== action.payload);
    },
    clearCart: (state) => {
      state.items = [];
      state.notes = "";
    },
    updateNotes: (state, action) => {
      state.notes = action.payload; // Add this to handle updating notes
    },
    updateAdditionalQuantities: (state, action) => {
      const { itemId, quantity } = action.payload;
      state.additionalQuantities[itemId] = quantity;
    },
  },
});

export const {
  addToCart,
  removeFromCart,
  clearCart,
  updateNotes,
  updateAdditionalQuantities,
} = cartSlice.actions;

export default cartSlice.reducer;
