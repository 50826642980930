import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query/react";
import { eventsApi } from "../api/eventsApi";
import { ordersApi } from "../api/ordersApi";
import cartReducer from "./cartSlice";
import dateSliceReducer from "./dateSlice";

export const store = configureStore({
  reducer: {
    // The API slice reducer (not using events rn)
    [eventsApi.reducerPath]: eventsApi.reducer,
    [ordersApi.reducerPath]: ordersApi.reducer,
    // The cart reducer
    cart: cartReducer,
    // New date range reducer
    eventDate: dateSliceReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(eventsApi.middleware, ordersApi.middleware),
});

setupListeners(store.dispatch);
