// SuperCategories.js
import React, { useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import "./superCategory.css";

const SuperCategories = () => {
  const [superCategories, setSuperCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const backendUrl = process.env.REACT_APP_BACKEND_URL;
    setLoading(true); // Start loading
    fetch(`${backendUrl}/api/categories/supercategories`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setSuperCategories(data);
        setError(null); // Clear any previous errors
      })
      .catch((error) => {
        console.error("Failed to fetch supercategories:", error);
        setError("Failed to fetch supercategories.");
      })
      .finally(() => setLoading(false)); // Stop loading regardless of outcome
  }, []);

  if (loading) return <p>Loading...</p>;
  if (error)
    return (
      <div>
        <p>Error: {error} </p>
        <p>
          {" "}
          DON'T RELOAD, return to calendar and click OK again. Reloading clears
          cart
        </p>
        <button onClick={() => navigate("/date")}>BACK TO CALENDAR</button>
        <p>If that does not work please reload</p>
      </div>
    );

  const encodedFileName = encodeURIComponent("Category Page Photo.jpg");
  const imageUrl = `${process.env.REACT_APP_BLOB_IMAGE_URL}/${encodedFileName}`;

  return (
    <div className="supercategories-container">
      <h2> PRIORUM PRICING </h2>
      <div className="supercategories-grid">
        {superCategories.map((superCategory) => (
          <NavLink
            key={superCategory._id}
            to={
              superCategory.hasCategories
                ? `/home/${superCategory._id}?name=${encodeURIComponent(
                    superCategory.name
                  )}`
                : `/home/supercategory/${
                    superCategory._id
                  }?name=${encodeURIComponent(superCategory.name)}`
            }
            className="supercategory-box"
          >
            <img
              src={`${
                process.env.REACT_APP_BLOB_IMAGE_URL
              }/${encodeURIComponent(superCategory.name.replace(/\//g, "-"))
                .replace(/-%20/g, "-")
                .replace(/%2B/g, "+")}.jpg`}
              alt={superCategory.name}
              className="supercategory-box-image"
            />
            <div className="supercategory-name">{superCategory.name}</div>
          </NavLink>
        ))}
      </div>
      <div className="image-container">
        <img src={imageUrl} alt="Category Page" className="category-image" />
      </div>
    </div>
  );
};

export default SuperCategories;
