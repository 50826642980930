import React, { useState, useEffect } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import ItemBox from "../../components/itemBox/itemBox";
import { useSelector } from "react-redux";
import { getAuth } from "firebase/auth";
import "./itemListPage.css";

const ItemListPage = () => {
  const { supercategoryId, categoryId } = useParams();
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [filteredItems, setFilteredItems] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [favorites, setFavorites] = useState([]);
  const eventDate = useSelector((state) => state.eventDate);
  const navigate = useNavigate();
  const cart = useSelector((state) => state.cart.items);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const name = queryParams.get("name");

  const fetchFavorites = async () => {
    try {
      const auth = getAuth();
      const token = await auth.currentUser.getIdToken();
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/user/favorites`,
        {
          headers: {
            Authorization: `Bearer ${token}`, // Send token in the Authorization header
          },
        }
      );
      const data = await response.json();
      setFavorites(data.favorites);
    } catch (err) {
      console.error("Failed to fetch favorites:", err);
    }
  };

  // Fetch user favorites from the backend
  useEffect(() => {
    fetchFavorites();
  }, []);

  useEffect(() => {
    if (!eventDate.date) {
      alert("Please select a date first.");
      navigate("/date");
      return;
    }

    setLoading(true);
    const backendUrl = process.env.REACT_APP_BACKEND_URL;
    // Updated URL construction logic to correctly handle supercategoryId and categoryId
    let urlParams = new URLSearchParams();
    if (eventDate.date) {
      const eventDateString = eventDate.date.split("T")[0];
      urlParams.append("eventDate", eventDateString);
    }
    if (supercategoryId) {
      urlParams.append("superCategoryId", supercategoryId);
    }
    if (categoryId) {
      urlParams.append("categoryId", categoryId);
    }
    let url = `${backendUrl}/api/items?${urlParams.toString()}`;

    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        setItems(data.data);
        setFilteredItems(data.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Failed to fetch items:", error);
        setLoading(false);
        setError("Failed to fetch items.");
      });
  }, [supercategoryId, categoryId, eventDate, navigate]);

  const handleSearch = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);

    const filtered = items.filter((item) =>
      item.ItemDescription.toLowerCase().includes(query)
    );
    setFilteredItems(filtered);
  };

  const toggleFavorite = async (itemId) => {
    try {
      const auth = getAuth();
      const token = await auth.currentUser.getIdToken();
      const isFavorited = favorites.includes(itemId.toString());
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/user/favorites`,
        {
          method: isFavorited ? "DELETE" : "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ itemId }),
        }
      );

      if (response.ok) {
        // Fetch the updated favorites after successfully adding/removing
        await fetchFavorites();
      }
    } catch (error) {
      console.error("Error updating favorites:", error);
    }
  };

  if (loading) {
    return <div>Getting Item Availability. Please Hold...</div>;
  }

  if (error)
    return (
      <div>
        <p>Error: {error} </p>
        <p>
          {" "}
          DON'T RELOAD, return to calendar and click OK again. Reloading clears
          cart
        </p>
        <button onClick={() => navigate("/date")}>BACK TO CALENDAR</button>
        <p>If that does not work please reload</p>
      </div>
    );

  return (
    <div>
      {name && <h1>{decodeURIComponent(name)}</h1>}
      <p>
        *Note: If higher quantities at regular pricing are needed enter on the
        cart page.
      </p>
      <div className="search-bar">
        <input
          type="text"
          placeholder="Search items..."
          value={searchQuery}
          onChange={handleSearch}
          className="search-input"
        />
      </div>
      <div className="button-container">
        <button onClick={() => navigate("/home")}>ADD MORE ITEMS</button>
      </div>
      <div className="items-container">
        {filteredItems.length > 0 ? (
          filteredItems.map((item) => (
            <ItemBox
              key={item.ItemID}
              item={item}
              name={name}
              quantityInCart={
                cart.find((cartItem) => cartItem.id === item.ItemID)
                  ?.quantity || 0
              }
              isFavorited={favorites.includes(item.ItemID.toString())}
              toggleFavorite={toggleFavorite}
            />
          ))
        ) : (
          <p>No items found for the search term "{searchQuery}".</p>
        )}
      </div>
    </div>
  );
};

export default ItemListPage;
